<template>
  <div class="statis-container">
    <div class="search-box">
      <!-- <Input size="large" search @on-search="handleSearchClick" placeholder="请输入项目名称" /> -->
      <!-- <Select v-model="sProject" filterable>
        <Option v-for="item in pList" :value="item.value" :key="item.value">{{ item.label }}</Option>
      </Select>-->
      <!-- 旧下拉框 -->
      <!-- <Select
        v-model="sProject"
        filterable
        remote
        placeholder="请输入项目名称"
        size="large"
        style="width: 260px"
        @on-change="handleSelectChange"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <Option
          v-for="(option, index) in pList"
          :value="option.id"
          :key="index">
          {{ option.label }}
        </Option>
      </Select>-->
      <Select
        v-model="currentPickProject"
        size="large"
        style="width: 260px"
        @on-change="handlePickProject"
        filterable
      >
        <Option
          v-for="item in pickProjectList"
          :value="item.value"
          :key="item.value"
        >{{ item.label }}</Option>
      </Select>
      <!-- <Button
        class="btn-primary"
        type="success"
        @click="handleSearchClick(1)">
        <Icon type="ios-search-outline"></Icon>
        搜索
      </Button>-->
      <Button class="btn-primary" type="success" size="large" @click="exportData(1)">
        <Icon type="ios-download-outline" />导出数据
      </Button>
      <Button class="btn-primary" type="info" size="large" @click="handleStatClick" v-if="isAdmin">
        <Icon type="md-stats" />统计分析
      </Button>
    </div>
    <!-- <div class="table-container">
      <Table
        border
        ref="table"
        size="large"
        id="table"
        :columns="columns"
        stripe
        :span-method="handleSpan"
        :data.sync="statisticsData"
      />
    </div>-->
    <div class="test-table">
      <el-table :data="tableData" size="mini" :span-method="arraySpanMethod" :cell-style="listCellStyle" border style="width: 100%">
        <el-table-column prop="projectName" align="center" label="项目" min-width="60"></el-table-column>
        <el-table-column prop="contractorCorpName" align="center" label="施工单位" min-width="60"></el-table-column>
        <el-table-column prop="teamName" align="center" label="参与班组" min-width="60"></el-table-column>
        <el-table-column prop="memberCount" align="center" label="班组总人数" min-width="50"></el-table-column>
        <el-table-column prop="taskName" align="center" label="培训任务" min-width="80"></el-table-column>
        <el-table-column prop="taskMemberCount" align="center" label="参与人数"min-width="50"></el-table-column>
        <el-table-column prop="completeCount" align="center" label="已完成任务人数" min-width="60"></el-table-column>
        <el-table-column prop="incompleteCount" align="center" label="未完成任务人数" min-width="60"></el-table-column>
        <el-table-column prop="taskDuration" align="center" label="总任务量(学时)" min-width="50"></el-table-column>
        <el-table-column prop="watchTotalTime" align="center" label="已完成任务量(学时)" min-width="50"></el-table-column>
        <el-table-column prop="unwatchTotalTime" align="center" label="未完成任务量(学时)" min-width="50"></el-table-column>
        <el-table-column prop="competePer" align="center" label="完成率(%)" width="60"></el-table-column>
      </el-table>
    </div>
    <div class="page-box">
      <Page :total="projectListTotal" :page-size="15" show-elevator @on-change="pageNumChange" />
    </div>
  </div>
</template>

<script>
import columns from "./table-columns";
import mockData from "./mock.js";
import XLSX from "xlsx";

export default {
  data() {
    return {
      statisticsData: [], // mockData,
      columns: columns.columns,
      sProject: "",
      pList: [],
      queryList: [],
      loading: false,
      projectCode: "",
      userInfo: {},
      currentPickProject: "",
      pickProjectList: [],
      tableData: [],
      pageCurrent: 1,
      projectListTotal: 0,
      currentProjectId: ''
    };
  },
  mounted() {
    this.userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO);
    this.fetchStatisticsList("");
    this.getAllProjectsByPhone();
  },
  computed: {
    isAdmin() {
      const KEY = this.userInfo.dg_key;
      return KEY === 1 || KEY === 2 || KEY === 6;
    }
  },
  methods: {
    // handleSearchClick(e) {},
    handleSpan() {},
    exportData() {
      if (this.currentProjectId == "") {
        this.$Message.warning("请先选择需要导出的数据");
        return;
      }
      let downUrl = ''
      downUrl = this.common.openUrl('8082','sapi',`/excel/projectStatic/${this.currentProjectId}/${this.userInfo.login_id}`);
      window.open(downUrl, "_blank");
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.fetchStatisticsList(query);
      } else {
        this.pList = [];
      }
    },
    async fetchStatisticsList(name) {
      let param = {
        phone: this.userInfo.login_id
      };
      if (name === "" || name === null) {
        param.vprojectcode = "9999";
      } else {
        param.vprojectname = name;
      }
      let res = await this.$fetch("/sapi/stat/taskStat", {
        method: "post",
        data: param
      });
      if (res) {
        // 模糊搜索时表格数据不刷新
        if (name) {
          this.loading = false;
          this.queryList = res.data.records;
          this.pList = res.data.records.map(item => {
            return {
              value: item.vprojectname,
              label: item.vprojectname,
              id: item.id
            };
          });

          if (res.data.records.length > 0) {
            this.projectCode = res.data.records[0].vprojectcode;
          }
        } else {
          this.statisticsData = res.data.records;
          if (this.statisticsData && this.statisticsData.length > 0) {
            this.projectCode = this.statisticsData[0].vprojectcode;
          }
        }
      }
    },
    handleStatClick() {
      this.$router.push({
        name: "allStatistics"
      });
    },
    handleSelectChange(val) {
      this.statisticsData = this.queryList.filter(item => item.id === val);
    },
    handleSearchClick(val) {
      this.fetchStatisticsList(val);
    },
    // 获取当前id所有项目列表
    async getAllProjectsByPhone() {
      let param = {
        phone: this.userInfo.login_id
      };
      // if (name === "" || name === null) {
      //   param.vprojectcode = "9999";
      // } else {
      //   param.vprojectname = name;
      // }
      let res = await this.$fetch("/sapi/task/getAllProjectsByPhone", {
        method: "post",
        data: param
      });
      let list = res.data;
      let pickList = [];
      list.forEach((val, index) => {
        pickList.push({ label: val.projectName, value: val.projectId });
      });
      this.pickProjectList = pickList;
    },
    handlePickProject(e) {
      console.log(e);
      this.tableData = []
      this.currentProjectId = e
      this.getTeamsTasksStatics(e);
    },
    async getTeamsTasksStatics(pid) {
      let param = {
        projectIds: [pid],
        pageCurrent: this.pageCurrent,
        pageSize: 15
      };
      // if (name === "" || name === null) {
      //   param.vprojectcode = "9999";
      // } else {
      //   param.vprojectname = name;
      // }
      let res = await this.$fetch("/sapi/task/getTeamsTasksStatics", {
        method: "post",
        data: param
      });
      let projectData = res.data;
      // console.log(projectData);
      if (projectData.data.length > 0) {
        this.projectListTotal = projectData.total;
        this.tableData = projectData.data;
        // projectData.data.forEach((val,index)=>{

        // })
      }
      // let pickList = []
      // list.forEach((val,index)=>{
      //   pickList.push({label:val.projectName,value:val.projectId})
      // })
      // this.pickProjectList = pickList
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      var num1 = 0;
      var num2 = 0;
      var num3 = 0;
      if (columnIndex == 0) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (row.projectName == this.tableData[i].projectName) {
            num1++;
          }
        }
        if (num1 == 1) {
          return {
            rowspan: num1,
            colspan: 1
          };
        } else if (num1 > 1) {
          if (
            this.tableData[rowIndex - 1] &&
            this.tableData[rowIndex].projectName ==
              this.tableData[rowIndex - 1].projectName
          ) {
            return {
              rowspan: 0,
              colspan: 0
            };
          } else {
            return {
              rowspan: num1,
              colspan: 1
            };
          }
        }
      }

      if (columnIndex == 1) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (row.contractorCorpName == this.tableData[i].contractorCorpName) {
            num2++;
          }
        }
        if (num2 == 1) {
          return {
            rowspan: num2,
            colspan: 1
          };
        } else if (num2 > 1) {
          if (
            this.tableData[rowIndex - 1] &&
            this.tableData[rowIndex].contractorCorpName ==
              this.tableData[rowIndex - 1].contractorCorpName
          ) {
            return {
              rowspan: 0,
              colspan: 0
            };
          } else {
            return {
              rowspan: num2,
              colspan: 1
            };
          }
        }
      }

      if (columnIndex == 2) {
        for (let i = 0; i < this.tableData.length; i++) {
          if (row.teamName == this.tableData[i].teamName) {
            num3++;
          }
        }
        if (num3 == 1) {
          return {
            rowspan: num3,
            colspan: 1
          };
        } else if (num3 > 1) {
          if (
            this.tableData[rowIndex - 1] &&
            this.tableData[rowIndex].teamName ==
              this.tableData[rowIndex - 1].teamName
          ) {
            return {
              rowspan: 0,
              colspan: 0
            };
          } else {
            return {
              rowspan: num3,
              colspan: 1
            };
          }
        }
      }
    },
    listCellStyle({ row, column, rowIndex, columnIndex }) {
      // if(columnIndex < 3){
      //     return {
      //         backgroundColor: "transparent"
      //     }
      // }
    },
    pageNumChange(e){
      this.pageCurrent = e
      this.getTeamsTasksStatics(this.currentProjectId)
    }
    // async getTeamsTasksStatics(){
    //   let param = {}
    //   param.projectIds = ''
    //   let res = await this.$fetch("/sapi/stat/taskStat", {
    //     method:"post",
    //     data: param
    //   });
    //   console.log(res)
    // }
  }
};
</script>
<style lang="less">
.page-box{
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 68px;
}
</style>
<style lang="stylus">
.statis-container {
  width: 100%;
  padding-top: 20px;

  .table-container {
    width: 100%;
    padding-bottom: 10px;
    overflow-x: scroll;

    table tr, table td, table th {
      border-color: #bcbcbd;
    }
  }

  .search-box {
    display: flex;
    margin-bottom: 20px;
  }

  .btn-primary {
    margin-left: 20px;
    color: #ffffff;
  }

  .ivu-table-cell {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
