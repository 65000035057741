
// 项目任务行信息处理
const TasksRow = (h, params, key) => {
  let rows  = params.row.tasks.map((task, index) => {
    const TaskLen = params.row.tasks.length
    const TeamLen = task.teams.length || 1
    const IsLast = (index + 1) === TaskLen
    
    return h("div", {
        style: {
          display: 'block',
          height: `${40 * TeamLen}px`,
          'line-height': `${40 * TeamLen}px`,
          'border-bottom': IsLast ? 'none' : '1px solid #bcbcbd'
        },
        // 普通的 HTML 特性
        attrs: {
          id: task.id
        },
      },
      task[key]
    )
  })
  return h("div", rows);
}

// 项目班组行信息处理
const TeamRow = (h, params, key) => {
  let rows = params.row.tasks.map((task, index) => {
    const TaskLen = params.row.tasks.length
    const TeamLen = task.teams.length
    const IsLastLen = (index + 1) === TaskLen
    return task.teams.map((team, tIdx) => {
      const IsLastTeam = (tIdx + 1) === TeamLen
      // 单元格显示数据
      let tdVal = ''
      if(key == 'name'){
        tdVal = task.name
      }else if(key == "create_time"){
        tdVal = task.create_time
      }else{
        tdVal = key !== 'prograss' ? team[key] : parseInt(( team.watch_total_time / team.task_duration ) * 100) + '%'
      }
      return h("div",
        {
          style: {
            display: 'block',
            height: `40px`,
            'line-height': `40px`,
            overflow: 'hidden',
            'text-overflow': 'ellipsis',
            'white-space': 'nowrap',
            'border-bottom': IsLastLen && IsLastTeam ? 'none' : '1px solid #bcbcbd',
          },
          // 普通的 HTML 特性
          attrs: {
            id: team.id,
            title: team[key] || tdVal
          },
        },
        tdVal
      )
    })
  })
  return h("div", rows);
}

export default {
  // 主表表头
  columns: [
    {
      title: "项目",
      key: "vprojectname",
      // width: 120,
      resizable: true,
      align: "center"
    },
    {
      title: "施工单位",
      key: "vcontractorCorpName",
      align: "center",
      tooltip: true,
      resizable: true,
      // width: 120,
    },
    {
      title: "培训任务",
      key: "tasks",
      align: "center",
      // width: 180,
      render: (h, params) => TeamRow(h, params, 'name')
    },
    {
      title: "任务创建时间",
      key: "tasks",
      align: "center",
      // width: 180,
      render: (h, params) => TeamRow(h, params, 'create_time')
    },
    {
      title: "培训任务视频时长(学时)",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TasksRow(h, params, 'class_hour')
    },
    {
      title: "参与班组",
      key: "vcategory",
      align: "center",
      tooltip: true,
      // width: 140,
      render: (h, params) => TeamRow(h, params, 'vteamName')
    },
    {
      title: "班组所属企业",
      key: "frame_type",
      align: "center",
      tooltip: true,
      // width: 240,
      render: (h, params) => TeamRow(h, params, 'corpName')
    },
    {
      title: "班组人数(人)",
      key: "vfunction_num",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'member_count')
    },
    {
      title: "班组参与人数(人)",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'task_member_count')
    },
    {
      title: "已完成任务人数（人）",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'complete_count')
    },
    {
      title: "未完成任务人数（人）",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'incomplete_count')
    },
    {
      title: "班组总任务量（学时）",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'task_duration')
    },
    {
      title: "已完成任务量（学时）",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'watch_total_time')
    },
    {
      title: "未完成任务量（学时）",
      key: "tasks",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'unwatch_total_time')
    },
    {
      title: "完成率 (%)",
      key: "prograss",
      align: "center",
      // width: 100,
      render: (h, params) => TeamRow(h, params, 'prograss')
    },
    // {
    //   title: "建筑面积（平方米）",
    //   key: "dbuilding_area",
    //   align: "center",
    //   width: 160
    // },
    // {
    //   title: "工程造价（万元）",
    //   key: "dinvest",
    //   align: "center",
    //   width: 160
    // },
    // {
    //   title: "工程地点",
    //   key: "vprojectaddress",
    //   align: "center",
    //   minWidth: 200,
    //   tooltip: true
    // }
  ],
}